<template>
    <div class="search-result-view">
        <div class="shop-search-box">
            <BorderBox :bg="true">
                <div class="shop-search">
                    <Title :text="title" />
                    <div class="search">
                        <el-input
                            class="search-input"
                            suffix-icon="el-icon-search"
                            placeholder="请搜索企业"
                            v-model="searchValue"
                            clearable
                            @input="search"
                            @change="search"
                            >
                        </el-input>
                    </div>
                </div>
            </BorderBox>
        </div>
         <div class="result-list-box" v-if="list && list.length > 0">
            <BorderBox :bg="true">
                <div class="result-list" v-if="list && list.length > 0">
                    <div v-for="item in list" :key="item.enterprise_id" :class="{item, active: active && item.enterprise_id === active.enterprise_id}" @click="selectList(item)">
                    {{item.enterprise_name}}
                    </div>
                </div>
            </BorderBox>
        </div>
    </div>

</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import Title from '@/components/Title.vue';

export default {
    name: 'ShopSearch',
    props: ['title', 'list', 'active', 'onSearch', 'onClick' ],
    components: { BorderBox, Title },
    data(){
        return {
            searchValue: '',
        }
    },
    methods:{
        search(value){
            this.$emit('onSearch', value);
        },
        selectList(item){
            this.$emit('onClick', item);
        }
    }
}
</script>

<style lang="less" scope>
.search-result-view{
    pointer-events: all;
    margin-top: 20px;
    width: 300px;
    .shop-search-box{
        border-radius: 10px;
        box-sizing: border-box;
        .shop-search{
            padding: 10px;
            // min-height: 98px;
            .search{
                padding: 10px;
                .search-input{
                    height: 32px;
                    .el-input__inner{
                        height: 32px;
                        background-color: rgba(8, 15, 28, 0.8);
                        border: none;
                        color: #fff;
                    }
                    .el-input__icon{
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .result-list-box{
        margin-top: 20px;
    }
    .result-list{
        padding: 10px 10px 5px 10px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(21, 67, 130, 0.6) 50%);
        .item{
            height: 25px;
            margin-bottom: 5px;
            background: rgba(76, 156, 255, 0.2);
            border: 1px solid #3C79B1;
            border-radius: 5px;
            color: #fff;
            font-size: 15px;
            padding: 0 0 0 15px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            &:hover{
                // border: 1px solid #7DEBFF;
                background: rgba(125, 235, 255, 0.2);
            }
            &::before{
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid #3C79B1;
                position: absolute;
                top: 5px;
                left: 5px;
                transform: rotate(-45deg);
            }

            &.active{
                border: 1px solid #7DEBFF;
                background: rgba(125, 235, 255, 0.2);
                &::before{
                    border-bottom: 5px solid #7DEBFF;
                }
            }
        }
    }
}
</style>
