<template>
  <div class="a1">
    <div :id="id" class="bar-chart-view"></div>
  </div>


</template>

<script>

import EventBus from '@/commons/event-bus.js'

import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';

import { GridComponent,TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export default {
    name: 'BarChartHor',
    props: ['data', 'id', 'onYAxisClick'],
    mounted(){
        this.initChart();
    },
    data(){
        return {
            myChart: null
        }
    },
    watch:{
        data(){
            this.setChartOption();
        }
    },
    methods:{
        initChart(){
            this.myChart = echarts.init(document.getElementById(this.id));
            var option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },

              grid: {
                left: '30%',
                top: 5,
                bottom:5,

              },

              xAxis: {
                type: 'value',
                axisLabel:{
                  show: false,
                },
                axisLine:{
                  show: false
                },
                splitLine:{
                  show: false
                },
                interval: 1,
                boundaryGap: ['0%', '0%']
              },
              yAxis: {
                type: 'category',
                triggerEvent: true,
                axisLabel:{
                  color: '#fff',
                  interval:0,
                  formatter: (text, index)=>{
            if(text.length<=11){
              return text.length>6?`${text.slice(0,6)}\n${text.slice(6,11)}`:text
            }else{
              return text.length > 6 ? `${text.slice(0,6)}\n${text.slice(6,11)}...` : text
            }
          }
        },
          axisLine:{
            show: false
          },
          splitLine:{
            show: false
          },
          axisTick:{
            show: false
          },
          minorSplitLine:{
            show: false
          },
          interval:1,
              boundaryGap: ["0","0"],
              data: []
        },
          series: []
        }
            this.myChart.setOption(option)

            this.setChartOption();

            this.myChart.on('click',(obj)=>{
                if(obj.componentType === "yAxis"){
                    EventBus.$emit('moveCenterByName', obj.value);
                }
            })
        },
        setChartOption(){
            const { ydata, xdata } = this.data;
            this.myChart.setOption({
                yAxis: {
                    data: xdata
                },
                series: ydata.map(item=>{
                    const { name, color, data, ...props } = item;
                    return {
                        name,
                        color,
                        data,
                        type: 'bar',
                        stack: 'total',
                        barWidth: 5,
                        barCategoryGap: '0',
                        emphasis: {
                            focus: 'series'
                        },
                        ...props,
                    }
                })
            })
          var autoHeight = xdata.length * 25 + 100;
            let elementsByClassNameElement = document.getElementsByClassName('bar-chart-view')[0];

          elementsByClassNameElement.style.height = autoHeight + "px";
          this.myChart.resize();
        }
    }
}
</script>

<style lang="less" scope>

  .bar-chart-view{
    width: 100%;
    //height: 100%;
  }
.a1{
  height: calc(100% - 20px);
  margin-top: 10px;
  overflow-y: hidden;
}
.a1:hover{
  overflow-x: hidden;
  overflow-y: auto;
}

 .a1::-webkit-scrollbar {    width: 10px;}/* 滚动槽 */
  ::-webkit-scrollbar-track {    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);    border-radius: 10px;}
  /* 滚动条滑块 */::-webkit-scrollbar-thumb {    border-radius: 10px;    background: rgba(0,0,0,0.1);     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); }
  ::-webkit-scrollbar-thumb:window-inactive {    background: rgba(255,0,0,0.4); }


</style>
