<template>
  <div class="circle-number">
      <div class="circle"></div>
      <div class="value">{{data.value}}</div>
      <div class="name">{{data.name}}</div>
  </div>
</template>

<script>
export default {
    name: 'CircleNumber',
    props: ['data'],
}
</script>

<style lang="less">
.circle-number{
    width: 167px;
    height: 100px;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    justify-content: center;
    align-items: center;
    .value{
        font-size: 20px;
        font-family: "AliHYAiHei";
        font-weight: 400;
        color: #FFFFFF;
    }
    .name{
        margin-top: 10px;
        font-size: 14px;
        font-family: "AliHYAiHei";
        font-weight: 400;
        color: #FFFFFF;
    }
    .circle{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/images/comons/circle.png') no-repeat;
        background-size: 167px 50px;
        background-position: 50% 80%;
        z-index: -1;
    }
}
</style>