<template>
    <BorderBox :bg="true">
        <div class="highcharts-pie-view">
            <Title :text="title" />
            <div class="chart-view">
                <div class="circle-box"></div>
                <div :id="id" class="chartbox"></div>
                <div class="img-box"></div>
            </div>
        </div>
    </BorderBox>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import Title from '@/components/Title.vue';

import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';

HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);

export default {
    name: 'HighchartsPieView',
    props: ['data', 'id', 'title'],
    components: { BorderBox, Title },
    computed: {
        total(){
            return this.data.reduce((total,item)=>{
                total += Number(item.y);
                return total;
            }, 0)
        }
    },
    data(){
        return {
            myChart: null
        }
    },
    mounted(){
        this.initCharts();
    },
    methods:{
        initCharts(){
            const total = this.total;
            this.myChart = Highcharts.chart(this.id, {
                    credits:{
                        enabled: false // 禁用版权信息
                    },
                    title: {
                        text: null
                    },
                    legend: {
                            layout: 'vertical',
                            align: 'right',
                            x: -20,
                            verticalAlign: 'middle',
                            floating: true,
                            itemMarginBottom: 7,
                            itemStyle:{
                                color: '#fff',
                            },
                            labelFormatter: function(a,b,c){

                                const per = (this.y / total * 100).toFixed(0);
                                return `${this.name} ${per}%`;
                            }
                            // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                    },
                    chart: {
                        type: 'pie',
                        spacing: [0,0,0,0],
                        backgroundColor: 'transparent',
                        options3d: {
                            enabled: true, // 是否启用 3D 功能，默认是 false，设置为 true 开启 3D 功能
                            alpha: 60,               // 内旋转角度
                            beta: 0,                // 外旋转角度
                            depth: 59,             // 图表的全深比，即为3D图X，Y轴的平面点固定，以图的Z轴原点为起始点上
                            viewDistance: 100,      // 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
                        },
                        marginRight: 150
                    },
                    plotOptions: {
                        pie: {
                            // allowPointSelect: true,
                            // cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    series: [
                         {
                            size: '100%',
                            innerSize: '85%',
                            data: [],
                            // data: this.data,
                            depth: 20, //扇区的高度
                        }
                    ]
            });

            this.setChartOption();
        },
        setChartOption(){
            this.myChart.series[0].setData(this.data) ;
        }
    },
    watch:{
        data(){
            this.setChartOption();
        }
    }
}
</script>

<style lang="less">
.highcharts-pie-view{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .chart-view{
        flex: 1 1 auto;
        position: relative;
        .circle-box{
            // pointer-events: none;
            position: absolute;
            width: 121px;
            height: 76px;
            top: 28px;
            left: 17px;
            background: url('../assets/images/risk/bg-circle.png') no-repeat;
        }
        .chartbox{
            width: 100%;
            height: 100%;
        }
        .img-box{
            pointer-events: none;
            position: absolute;
            width: 21px;
            height: 24px;
            top: 45px;
            left: 67px;
            background: url('../assets/images/risk/shield.png') no-repeat;
        }
    }
}

.highcharts-pie-series.highcharts-color-0{
    .highcharts-point {
        fill: rgba(0,0,0,0);
        stroke-width: 2px;
        stroke: #E86452;
    }
}
.highcharts-pie-series.highcharts-color-1{
    .highcharts-point {
        fill: rgba(0,0,0,0);
        stroke-width: 2px;
        stroke: #FFB462;
    }
}
.highcharts-pie-series.highcharts-color-2{
    .highcharts-point {
        fill: rgba(0,0,0,0);
        stroke-width: 2px;
        stroke: #FFD470;
    }
}
.highcharts-pie-series.highcharts-color-3{
    .highcharts-point {
        fill: rgba(0,0,0,0);
        stroke-width: 2px;
        stroke: #2385FF;
    }
}

</style>
